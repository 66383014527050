<script setup>
import '@/assets/sass/widgets/widgets.scss';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import ApexChart from 'vue3-apexcharts';

import { useMeta } from '@/composables/use-meta';
useMeta({ title: 'Продажи' });

const store = useStore();

//Revenue
const revenue_series = ref([
    { name: 'Новые', data: [16800, 16800, 15500, 17800, 15500, 17000, 19000, 16000, 15000, 17000, 14000, 17000] },
    { name: 'Завершенные', data: [16500, 17500, 16200, 17300, 16000, 19500, 16000, 17000, 16000, 19000, 18000, 19000] },
]);
const revenue_options = computed(() => {
    const is_dark = store.state.is_dark_mode;
    return {
        chart: {
            fontFamily: 'Nunito, sans-serif',
            zoom: { enabled: false },
            toolbar: { show: false },
        },
        dataLabels: { enabled: false },
        stroke: { show: true, curve: 'smooth', width: 2, lineCap: 'square' },
        dropShadow: { enabled: true, opacity: 0.2, blur: 10, left: -7, top: 22 },
        colors: is_dark ? ['#2196f3', '#e7515a'] : ['#1b55e2', '#e7515a'],
        markers: {
            discrete: [
                { seriesIndex: 0, dataPointIndex: 6, fillColor: '#1b55e2', strokeColor: '#fff', size: 7 },
                { seriesIndex: 1, dataPointIndex: 5, fillColor: '#e7515a', strokeColor: '#fff', size: 7 },
            ],
        },
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        xaxis: {
            axisBorder: { show: false },
            axisTicks: { show: false },
            crosshairs: { show: true },
            labels: { offsetX: 0, offsetY: 5, style: { fontSize: '12px', fontFamily: 'Nunito, sans-serif', cssClass: 'apexcharts-xaxis-title' } },
        },
        yaxis: {
            tickAmount: 7,
            labels: {
                formatter: function (value) {
                    return value / 1000 + 'K';
                },
                offsetX: -10,
                offsetY: 0,
                style: { fontSize: '12px', fontFamily: 'Nunito, sans-serif', cssClass: 'apexcharts-yaxis-title' },
            },
        },
        grid: {
            borderColor: is_dark ? '#191e3a' : '#e0e6ed',
            strokeDashArray: 5,
            xaxis: { lines: { show: true } },
            yaxis: { lines: { show: false } },
            padding: { top: 0, right: 0, bottom: 0, left: 0 },
        },
        legend: {
            position: 'top',
            horizontalAlign: 'right',
            offsetY: 0,
            fontSize: '16px',
            fontFamily: 'Nunito, sans-serif',
            markers: { width: 10, height: 10, strokeWidth: 0, strokeColor: '#fff', fillColors: undefined, radius: 12, onClick: undefined, offsetX: 0, offsetY: 0 },
            itemMargin: { horizontal: 20, vertical: 5 },
        },
        tooltip: { theme: 'dark', marker: { show: true }, x: { show: false } },
        fill: {
            type: 'gradient',
            gradient: {
                type: 'vertical',
                shadeIntensity: 1,
                inverseColors: !1,
                opacityFrom: is_dark ? 0.19 : 0.28,
                opacityTo: 0.05,
                stops: is_dark ? [100, 100] : [45, 100],
            },
        },
    };
});

//Daily Sales
const daily_sales_series = ref([
    { name: 'Sales', data: [44, 55, 41, 67, 22, 43, 21] },
    { name: 'Last Week', data: [13, 23, 20, 8, 13, 27, 33] },
]);
const daily_sales_options = computed(() => {
    return {
        chart: { toolbar: { show: false }, stacked: true, stackType: '100%' },
        dataLabels: { enabled: false },
        stroke: { show: true, width: 1 },
        colors: ['#e2a03f', '#e0e6ed'],
        responsive: [{ breakpoint: 480, options: { legend: { position: 'bottom', offsetX: -10, offsetY: 0 } } }],
        xaxis: { labels: { show: false }, categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'] },
        yaxis: { show: false },
        fill: { opacity: 1 },
        plotOptions: { bar: { horizontal: false, columnWidth: '25%' } },
        legend: { show: false },
        grid: {
            show: false,
            xaxis: { lines: { show: false } },
            padding: { top: 10, right: -20, bottom: -20, left: -20 },
        },
    };
});

//Total Orders
const total_orders_series = ref([{ name: 'Sales', data: [28, 40, 36, 52, 38, 60, 38, 52, 36, 40] }]);
const total_orders_options = computed(() => {
    const is_dark = store.state.is_dark_mode;
    return {
        chart: { sparkline: { enabled: true } },
        stroke: { curve: 'smooth', width: 2 },
        colors: is_dark ? ['#1abc9c'] : ['#fff'],
        labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
        yaxis: { min: 0, show: false },
        grid: { padding: { top: 125, right: 0, bottom: 0, left: 0 } },
        fill: {
            opacity: 1,
            type: 'gradient',
            gradient: {
                type: 'vertical',
                shadeIntensity: 1,
                inverseColors: !1,
                opacityFrom: is_dark ? 0.3 : 0.4,
                opacityTo: 0.05,
                stops: is_dark ? [100, 100] : [45, 100],
            },
        },
        tooltip: { x: { show: false }, theme: 'dark' },
    };
});

//Sales by Category
const sales_donut_series = ref([985, 737, 270, 345]);
const sales_donut_options = computed(() => {
    const is_dark = store.state.is_dark_mode;
    const option = {
        labels: ['Новые', 'Сборка', 'Упаковка', 'Доставка'],
        chart: {},
        dataLabels: { enabled: false },
        expandOnClick: is_dark ? false : true,
        stroke: { show: true, width: 25, colors: is_dark ? '#0e1726' : '#fff' },
        colors: is_dark ? ['#e2a03f', '#2196f3', '#e7515a', '#8dbf42', '#e2a03f'] : ['#e2a03f', '#2196f3', '#e7515a', '#8dbf42'],
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            fontSize: '14px',
            markers: { width: 10, height: 10 },
            height: 50,
            offsetY: 20,
            itemMargin: { horizontal: 8, vertical: 0 },
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '65%',
                    background: 'transparent',
                    labels: {
                        show: true,
                        name: { show: true, fontSize: '29px', fontFamily: 'Nunito, sans-serif', offsetY: -10 },
                        value: {
                            show: true,
                            fontSize: '26px',
                            fontFamily: 'Nunito, sans-serif',
                            color: is_dark ? '#bfc9d4' : undefined,
                            offsetY: 16,
                            formatter: function (val) {
                                return val;
                            },
                        },
                        total: {
                            show: true,
                            label: 'Всего',
                            color: '#888ea8',
                            fontSize: '29px',
                            formatter: function (w) {
                                return w.globals.seriesTotals.reduce(function (a, b) {
                                    return a + b;
                                }, 0);
                            },
                        },
                    },
                },
            },
        },
    };

    if (is_dark) {
        option['states'] = {
            hover: { filter: { type: 'none' } },
            active: { filter: { type: 'none' } },
        };
    }

    return option;
});
</script>

<template>
    <div class="layout-px-spacing dash_1">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:;">Мониторинг</a></li>
                                <li class="breadcrumb-item active" aria-current="page"><span>Продажи</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>

        <div class="row layout-top-spacing">
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
                <div class="widget widget-summary">
                    <div class="widget-heading">
                        <h5>В работе</h5>
                        <div class="dropdown btn-group">
                            <a href="javascript:;" id="ddlSummary" class="btn dropdown-toggle btn-icon-only"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-more-horizontal">
                                    <circle cx="12" cy="12" r="1"></circle>
                                    <circle cx="19" cy="12" r="1"></circle>
                                    <circle cx="5" cy="12" r="1"></circle>
                                </svg>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="ddlSummary">
                                <li><a href="javascript:;" class="dropdown-item">View Report</a></li>
                                <li><a href="javascript:;" class="dropdown-item">Edit Report</a></li>
                                <li><a href="javascript:;" class="dropdown-item">Mark as Done</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="widget-content">
                        <div class="summary-list">
                            <div class="w-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-shopping-bag">
                                    <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                                    <line x1="3" y1="6" x2="21" y2="6"></line>
                                    <path d="M16 10a4 4 0 0 1-8 0"></path>
                                </svg>
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Новые</h6>
                                    <p class="summary-count">$92,600</p>
                                </div>
                                <div class="w-summary-stats">
                                    <div class="progress">
                                        <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="90"
                                            class="progress-bar bg-gradient-secondary" style="width: 90%"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="summary-list">
                            <div class="w-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-tag">
                                    <path
                                        d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z">
                                    </path>
                                    <line x1="7" y1="7" x2="7" y2="7"></line>
                                </svg>
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Сборка</h6>
                                    <p class="summary-count">$37,515</p>
                                </div>
                                <div class="w-summary-stats">
                                    <div class="progress">
                                        <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="65"
                                            class="progress-bar bg-gradient-success" style="width: 65%"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="summary-list">
                            <div class="w-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-tag">
                                    <path
                                        d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z">
                                    </path>
                                    <line x1="7" y1="7" x2="7" y2="7"></line>
                                </svg>
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Собран</h6>
                                    <p class="summary-count">$37,515</p>
                                </div>
                                <div class="w-summary-stats">
                                    <div class="progress">
                                        <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="65"
                                            class="progress-bar bg-gradient-success" style="width: 65%"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="summary-list">
                            <div class="w-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-tag">
                                    <path
                                        d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z">
                                    </path>
                                    <line x1="7" y1="7" x2="7" y2="7"></line>
                                </svg>
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Упакован</h6>
                                    <p class="summary-count">$37,515</p>
                                </div>
                                <div class="w-summary-stats">
                                    <div class="progress">
                                        <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="65"
                                            class="progress-bar bg-gradient-success" style="width: 65%"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="summary-list">
                            <div class="w-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-credit-card">
                                    <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                                    <line x1="1" y1="10" x2="23" y2="10"></line>
                                </svg>
                            </div>
                            <div class="w-summary-details">
                                <div class="w-summary-info">
                                    <h6>Просрочены</h6>
                                    <p class="summary-count">$55,085</p>
                                </div>
                                <div class="w-summary-stats">
                                    <div class="progress">
                                        <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="80"
                                            class="progress-bar bg-gradient-warning" style="width: 80%"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-sales-category">
                    <div class="widget-heading">
                        <h5>Активные заказы</h5>
                    </div>
                    <div class="widget-content">
                        <apex-chart v-if="sales_donut_options" height="460" type="donut" :options="sales_donut_options"
                            :series="sales_donut_series"></apex-chart>
                    </div>
                </div>
            </div>

            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-recent-activity">
                    <div class="widget-heading">
                        <h5>Последняя активность</h5>
                    </div>
                    <div class="widget-content">
                        <perfect-scrollbar class="timeline-line">
                            <div class="item-timeline timeline-primary">
                                <div class="badge badge-primary"></div>
                                <div class="t-text">
                                    <p><span>Updated</span> Server Logs</p>
                                    <span
                                        class="badge badge-outline-primary outline-badge-primary icon-fill-primary">Pending</span>
                                    <p class="t-time">Just Now</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-success">
                                <div class="badge badge-success"></div>
                                <div class="t-text">
                                    <p>Send Mail to <a href="javascript:void(0);">HR</a> and <a
                                            href="javascript:void(0);">Admin</a></p>
                                    <span
                                        class="badge badge-outline-success outline-badge-success icon-fill-success">Completed</span>
                                    <p class="t-time">2 min ago</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-danger">
                                <div class="badge badge-danger"></div>
                                <div class="t-text">
                                    <p>Backup <span>Files EOD</span></p>
                                    <span
                                        class="badge badge-outline-danger outline-badge-danger icon-fill-danger">Pending</span>
                                    <p class="t-time">14:00</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-dark">
                                <div class="badge badge-dark"></div>
                                <div class="t-text">
                                    <p>Collect documents from <a href="javascript:void(0);">Sara</a></p>
                                    <span
                                        class="badge badge-outline-dark outline-badge-dark icon-fill-dark">Completed</span>
                                    <p class="t-time">16:00</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-warning">
                                <div class="badge badge-warning"></div>
                                <div class="t-text">
                                    <p>Conference call with <a href="javascript:void(0);">Marketing Manager</a>.</p>
                                    <span class="badge badge-outline-warning outline-badge-warning icon-fill-warning">In
                                        progress</span>
                                    <p class="t-time">17:00</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-secondary">
                                <div class="badge badge-secondary"></div>
                                <div class="t-text">
                                    <p>Rebooted Server</p>
                                    <span
                                        class="badge badge-outline-secondary outline-badge-secondary icon-fill-secondary">Completed</span>
                                    <p class="t-time">17:00</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-warning">
                                <div class="badge badge-warning"></div>
                                <div class="t-text">
                                    <p>Send contract details to Freelancer</p>
                                    <span
                                        class="badge badge-outline-warning outline-badge-warning icon-fill-warning">Pending</span>
                                    <p class="t-time">18:00</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-dark">
                                <div class="badge badge-dark"></div>
                                <div class="t-text">
                                    <p>Kelly want to increase the time of the project.</p>
                                    <span class="badge badge-outline-dark outline-badge-dark icon-fill-dark">In
                                        Progress</span>
                                    <p class="t-time">19:00</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-success">
                                <div class="badge badge-success"></div>
                                <div class="t-text">
                                    <p>Server down for maintanence</p>
                                    <span
                                        class="badge badge-outline-success outline-badge-success icon-fill-success">Completed</span>
                                    <p class="t-time">19:00</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-secondary">
                                <div class="badge badge-secondary"></div>
                                <div class="t-text">
                                    <p>Malicious link detected</p>
                                    <span
                                        class="badge badge-outline-secondary outline-badge-secondary icon-fill-secondary">Block</span>
                                    <p class="t-time">20:00</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-warning">
                                <div class="badge badge-warning"></div>
                                <div class="t-text">
                                    <p>Rebooted Server</p>
                                    <span
                                        class="badge badge-outline-warning outline-badge-warning icon-fill-warning">Completed</span>
                                    <p class="t-time">23:00</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-primary">
                                <div class="badge badge-primary"></div>
                                <div class="t-text">
                                    <p><span>Updated</span> Server Logs</p>
                                    <span
                                        class="badge badge-outline-primary outline-badge-primary icon-fill-primary">Pending</span>
                                    <p class="t-time">Just Now</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-success">
                                <div class="badge badge-success"></div>
                                <div class="t-text">
                                    <p>Send Mail to <a href="javascript:void(0);">HR</a> and <a
                                            href="javascript:void(0);">Admin</a></p>
                                    <span
                                        class="badge badge-outline-success outline-badge-success icon-fill-success">Completed</span>
                                    <p class="t-time">2 min ago</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-danger">
                                <div class="badge badge-danger"></div>
                                <div class="t-text">
                                    <p>Backup <span>Files EOD</span></p>
                                    <span
                                        class="badge badge-outline-danger outline-badge-danger icon-fill-danger">Pending</span>
                                    <p class="t-time">14:00</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-dark">
                                <div class="badge badge-dark"></div>
                                <div class="t-text">
                                    <p>Collect documents from <a href="javascript:void(0);">Sara</a></p>
                                    <span
                                        class="badge badge-outline-dark outline-badge-dark icon-fill-dark">Completed</span>
                                    <p class="t-time">16:00</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-warning">
                                <div class="badge badge-warning"></div>
                                <div class="t-text">
                                    <p>Conference call with <a href="javascript:void(0);">Marketing Manager</a>.</p>
                                    <span class="badge badge-outline-warning outline-badge-warning icon-fill-warning">In
                                        progress</span>
                                    <p class="t-time">17:00</p>
                                </div>
                            </div>
                        </perfect-scrollbar>

                        <div class="tm-action-btn">
                            <button type="button" class="btn btn-default">
                                <span>Посмотреть все</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-arrow-right">
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                    <polyline points="12 5 19 12 12 19"></polyline>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-recent-orders">
                    <div class="widget-heading">
                        <h5>Последние заказы</h5>
                    </div>
                    <div class="widget-content table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>
                                        <div class="th-content">Номер</div>
                                    </th>
                                    <th>
                                        <div class="th-content">Дата</div>
                                    </th>
                                    <th>
                                        <div class="th-content">Покупатель</div>
                                    </th>
                                    <th>
                                        <div class="th-content th-heading">Сумма</div>
                                    </th>
                                    <th>
                                        <div class="th-content">Статус</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="td-content"><img src="@/assets/images/profile-13.jpeg"
                                                alt="avatar" /><span>Luke Ivory</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content text-primary">Headphone</div>
                                    </td>
                                    <td>
                                        <div class="td-content">#46894</div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span>$56.07</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="badge badge-success">Paid</span></div>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <div class="td-content"><img src="@/assets/images/profile-7.jpeg"
                                                alt="avatar" /><span>Andy King</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content text-warning">Nike Sport</div>
                                    </td>
                                    <td>
                                        <div class="td-content">#76894</div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span>$88.00</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="badge badge-primary">Shipped</span></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="td-content"><img src="@/assets/images/profile-10.jpeg"
                                                alt="avatar" /><span>Laurie Fox</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content text-danger">Sunglasses</div>
                                    </td>
                                    <td>
                                        <div class="td-content">#66894</div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span>$126.04</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="badge badge-success">Paid</span></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="td-content"><img src="@/assets/images/profile-5.jpeg"
                                                alt="avatar" /><span>Ryan Collins</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content text-warning">Sport</div>
                                    </td>
                                    <td>
                                        <div class="td-content">#89891</div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span>$108.09</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="badge badge-primary">Shipped</span></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="td-content"><img src="@/assets/images/profile-4.jpeg"
                                                alt="avatar" /><span>Irene Collins</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content text-primary">Speakers</div>
                                    </td>
                                    <td>
                                        <div class="td-content">#75844</div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span>$84.00</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="badge badge-danger">Pending</span></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="td-content"><img src="@/assets/images/profile-11.jpeg"
                                                alt="avatar" /><span>Sonia Shaw</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content text-danger">Watch</div>
                                    </td>
                                    <td>
                                        <div class="td-content">#76844</div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span>$110.00</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="badge badge-success">Paid</span></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-top-selling-products">
                    <div class="widget-heading">
                        <h5>Изделия в работе</h5>
                    </div>

                    <div class="widget-content table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>
                                        <div class="th-content">Артикул</div>
                                    </th>
                                    <th>
                                        <div class="th-content th-heading">Наименование</div>
                                    </th>
                                    <th>
                                        <div class="th-content th-heading">Новый</div>
                                    </th>
                                    <th>
                                        <div class="th-content">Сборка</div>
                                    </th>
                                    <th>
                                        <div class="th-content">Собран</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="td-content d-flex">
                                            <img src="@/assets/images/product-headphones.jpg" alt="product" />
                                            <div class="align-self-center">
                                                <p class="prd-name">Headphone</p>
                                                <p class="prd-category text-primary">Digital</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="pricing">$168.09</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="discount-pricing">$60.09</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content">170</div>
                                    </td>
                                    <td>
                                        <div class="td-content">
                                            <a href="javascript:void(0);" class="text-danger"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-chevrons-right">
                                                    <polyline points="13 17 18 12 13 7"></polyline>
                                                    <polyline points="6 17 11 12 6 7"></polyline>
                                                </svg>
                                                Direct</a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="td-content d-flex">
                                            <img src="@/assets/images/product-shoes.jpg" alt="product" />
                                            <div class="align-self-center">
                                                <p class="prd-name">Shoes</p>
                                                <p class="prd-category text-warning">Faishon</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="pricing">$108.09</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="discount-pricing">$47.09</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content">130</div>
                                    </td>
                                    <td>
                                        <div class="td-content">
                                            <a href="javascript:void(0);" class="text-primary"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-chevrons-right">
                                                    <polyline points="13 17 18 12 13 7"></polyline>
                                                    <polyline points="6 17 11 12 6 7"></polyline>
                                                </svg>
                                                Google</a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="td-content d-flex">
                                            <img src="@/assets/images/product-watch.jpg" alt="product" />
                                            <div class="align-self-center">
                                                <p class="prd-name">Watch</p>
                                                <p class="prd-category text-danger">Accessories</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="pricing">$88.00</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="discount-pricing">$20.00</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content">66</div>
                                    </td>
                                    <td>
                                        <div class="td-content">
                                            <a href="javascript:void(0);" class="text-warning"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-chevrons-right">
                                                    <polyline points="13 17 18 12 13 7"></polyline>
                                                    <polyline points="6 17 11 12 6 7"></polyline>
                                                </svg>
                                                Ads</a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="td-content d-flex">
                                            <img src="@/assets/images/product-laptop.jpg" alt="product" />
                                            <div class="align-self-center">
                                                <p class="prd-name">Laptop</p>
                                                <p class="prd-category text-primary">Digital</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="pricing">$110.00</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="discount-pricing">$33.00</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content">35</div>
                                    </td>
                                    <td>
                                        <div class="td-content">
                                            <a href="javascript:void(0);" class="text-info"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-chevrons-right">
                                                    <polyline points="13 17 18 12 13 7"></polyline>
                                                    <polyline points="6 17 11 12 6 7"></polyline>
                                                </svg>
                                                Email</a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="td-content d-flex">
                                            <img src="@/assets/images/product-camera.jpg" alt="product" />
                                            <div class="align-self-center">
                                                <p class="prd-name">Camera</p>
                                                <p class="prd-category text-primary">Digital</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="pricing">$126.04</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="discount-pricing">$26.04</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content">30</div>
                                    </td>
                                    <td>
                                        <div class="td-content">
                                            <a href="javascript:void(0);" class="text-secondary"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-chevrons-right">
                                                    <polyline points="13 17 18 12 13 7"></polyline>
                                                    <polyline points="6 17 11 12 6 7"></polyline>
                                                </svg>
                                                Referral</a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>